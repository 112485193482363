<template>
  <div>
    <div id="idItemListList">
      <b-row>
        <b-col
          cols="12"
        >
          <b-table
            ref="refItemTable"
            thead-class="itemTableHeader"
            tbody-tr-class="itemTableRow"
            primary-key="id"
            responsive
            show-empty
            striped
            empty-text="Não há itens cadastrados."
            :items="items"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center my-3">
                <b-spinner
                  variant="info"
                  class="align-middle mb-1"
                />
                <br>
                <strong class="pt-2">Carregando...</strong>
              </div>
            </template>
            <template #cell(field_number)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.numeroItem }}
                </span>
              </b-media>
            </template>

            <template #cell(field_desciption)="field">
              <b-media vertical-align="center">
                <span
                  v-b-tooltip.hover
                  class="font-weight-bold d-block text-nowrap"
                  :title="`DESCRIÇÃO: ${field.item.descricao}`"
                >
                  {{ field.item.descricao | truncate(30, '...') }}
                </span>
              </b-media>
            </template>

            <template #cell(field_quantity)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.quantidade }}
                </span>
              </b-media>
            </template>

            <template #cell(field_unitary_value)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  R$ {{ field.item.valorUnitarioEstimado | formatCurrency() }}
                </span>
              </b-media>
            </template>

            <template #cell(field_total_value)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  R$ {{ field.item.valorTotal | formatCurrency() }}
                </span>
              </b-media>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="mx-2 my-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="page"
              :total-rows="itemsAmount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BMedia,
  BPagination,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BMedia,
    BSpinner,
    BPagination,
  },
  filters: {
    formatCurrency,
    truncate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    cnpj: {
      type: [String, Number],
      required: true,
    },
    year: {
      type: [String, Number],
      required: true,
    },
    seq: {
      type: [String, Number],
      required: true,
    },
    itemsAmount: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    const isLoading = true

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)
    const page = 1
    const perPage = 5
    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'field_number',
        label: 'Número',
        thStyle: { width: '10%' },
      },
      {
        key: 'field_desciption',
        label: 'Descrição',
        thStyle: { width: '5%' },
      },
      {
        key: 'field_quantity',
        label: 'Quantidade',
        thStyle: { width: '5%' },
      },
      {
        key: 'field_unitary_value',
        label: 'Valor Unitário Estimado',
        thStyle: { width: '22%' },
      },
      {
        key: 'field_total_value',
        label: 'Valor Total Estimado',
        thStyle: { width: '22%' },
      },
    ]

    const items = []

    return {
      refFormObserver,
      getValidationState,
      isLoading,
      sortBy,
      tableColumns,
      isSortDirDesc,
      items,
      page,
      perPage,
    }
  },
  watch: {
    page: 'fetchItemsList',
  },
  created() {
    this.fetchItemsList()
  },
  methods: {
    fetchItemsList() {
      syslic
        .pncp
        .fetchBiddingItemsPage(
          this.cnpj,
          this.year,
          this.seq,
          this.page,
          this.perPage,
        )
        .then(response => {
          this.items = response.data
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao buscar os itens desta licitação no Portal Nacional de Contratações Públicas.',
              text: 'Houve uma falha ao requisitar os itens através do portal',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
      this.isLoading = false
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idItemListList .itemTableHeader th {
  color: $primary;
  font-weight: 600;
  font-size: 0.9rem;
  background-color: #E3EDF3;
}

#idItemListList .itemListHeader {
  background-color: #F6F6F9
}

#idItemListList .itemTableRow {
  cursor: default;
}
#idItemListList .headerTitle {
  color: $primary;
  font-weight: 600;
  font-size: 1.25rem;
}

#idItemListList .headerContent {
  color: #464646;
}

#idItemListList .itemIconType {
  color: $primary;
}

#btn-link-bidding {
  border: none !important;
}
</style>
