<template>
  <div>
    <b-row
      id="idPncpBiddingFiles"
      class="justify-content-center"
    >
      <b-col
        cols="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body class="p-0">
            <div
              v-show="!isLoaded"
              class="px-2 py-2"
            >
              <div class="text-center my-3">
                <b-spinner
                  variant="info"
                  class="align-middle mb-1"
                />
                <br>
                <strong class="pt-2">Carregando...</strong>
              </div>
            </div>
            <div
              v-show="isLoaded"
            >
              <b-table
                ref="refItemTable"
                thead-class="itemTableHeader"
                tbody-tr-class="itemTableRow"
                responsive
                show-empty
                striped
                hover
                empty-text="Não há arquivos cadastrados."
                :items="files"
                :fields="tableColumns"
              >
                <template #cell(field_name)="field">
                  <b-media vertical-align="center">
                    <span
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ field.item.titulo }}
                    </span>
                  </b-media>
                </template>

                <template #cell(field_date)="field">
                  <b-media vertical-align="center">
                    <span
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ moment(field.item.dataPublicacaoPncp).format('DD/MM/YYYY') }}
                    </span>
                  </b-media>
                </template>

                <template #cell(field_type)="field">
                  <b-media vertical-align="center">
                    <span
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ field.item.tipoDocumentoNome }}
                    </span>
                  </b-media>
                </template>

                <template #cell(field_link)="field">
                  <b-media vertical-align="center">
                    <span
                      class="font-weight-bold d-block text-nowrap"
                    >
                      <b-button
                        class="btn-icon rounded-circle clsItemOpen"
                        variant="flat-primary"
                        size="md"
                        :href="field.item.url"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiDownload"
                        />
                      </b-button>
                    </span>
                  </b-media>
                </template>
              </b-table>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BSpinner,
  BTable,
  BMedia,
  BButton,
} from 'bootstrap-vue'

import {
  mdiDownload,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BSpinner,
    BTable,
    BMedia,
    SvgIcon,
  },
  props: {
    files: {
      type: [Object, Array],
      required: true,
    },
  },
  emits: ['ready'],
  data() {
    const isLoaded = false

    const tableColumns = [
      {
        key: 'field_name',
        label: 'Nome',
        thStyle: { width: '30%' },
        thClass: 'clsFileHeader',
      },
      {
        key: 'field_date',
        label: 'Data',
        thStyle: { width: '20%' },
        thClass: 'clsFileHeader',
      },
      {
        key: 'field_type',
        label: 'Tipo',
        thStyle: { width: '25%' },
        thClass: 'clsFileHeader',
      },
      {
        key: 'field_link',
        label: 'Baixar',
        thStyle: { width: '25%' },
        thClass: 'clsFileHeader',
      },
    ]
    return {
      isLoaded,
      tableColumns,
    }
  },
  created() {
    this.isLoaded = true
  },
  methods: {
  },
  setup() {
    return {
      moment,
      mdiDownload,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idPncpBiddingFiles {
  .clsFileHeader {
    color: $primary;
    background-color: rgb(227, 237, 243);
  }
}

</style>
