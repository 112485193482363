<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
        >
          <b-row class="justify-content-center">
            <b-col>
              <b-card
                no-body
                class="roundedTopRightCorner overflow-hidden"
              >
                <b-card-title class="cardTitle">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="setColorPrimary my-1 ml-2">
                      {{ data.tipoInstrumentoConvocatorioNome.toUpperCase() }} Nº: {{ data.numeroCompra }}/{{ data.anoCompra }}
                    </span>
                    <div
                      v-if="isImported"
                      class="d-flex justify-content-start"
                    >
                      <b-button
                        id="btnBiddingImported"
                        class="d-flex flex-column align-items-center btn-icon px-2"
                        variant="outline-light"
                        size="sm"
                        @click="goToBidding()"
                      >
                        <svg-icon
                          id="icon-btn-bid-imported"
                          class="m-50"
                          type="mdi"
                          size="40"
                          :path="mdiClipboardCheckOutline"
                        />
                        <span class="d-inline mb-50">Licitação</span>
                        <span class="d-inline mb-50">Importada</span>
                      </b-button>
                    </div>
                  </div>
                </b-card-title>
                <b-card-body>
                  <b-row class="mb-2">
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Local: </span>
                      <br>
                      <span>{{ data.unidadeOrgao.municipioNome }}/{{ data.unidadeOrgao.ufSigla }}</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Órgão: </span>
                      <br>
                      <span>{{ data.orgaoEntidade.razaoSocial }}</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Unidade compradora: </span>
                      <br>
                      <span>{{ data.unidadeOrgao.codigoUnidade }} - {{ data.unidadeOrgao.nomeUnidade }}</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Modalidade da contratação: </span>
                      <br>
                      <span>{{ data.modalidadeNome }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Amparo legal: </span>
                      <br>
                      <span>{{ data.amparoLegal.nome }}</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Tipo: </span>
                      <br>
                      <span>{{ data.tipoInstrumentoConvocatorioNome }}</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Modo de Disputa: </span>
                      <br>
                      <span>{{ data.modoDisputaNome }}</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Registro de preço: </span>
                      <br>
                      <span v-if="data.srp"> Sim </span>
                      <span v-else> Não </span>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Data de divulgação no PNCP: </span>
                      <br>
                      <span>{{ moment(data.dataPublicacaoPncp).format('DD/MM/YYYY') }}</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Data de início de recebimento de propostas: </span>
                      <br>
                      <span>{{ moment(data.dataAberturaProposta).format('DD/MM/YYYY') }}</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Data fim de recebimento de propostas: </span>
                      <br>
                      <span>{{ moment(data.dataEncerramentoProposta).format('DD/MM/YYYY') }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">ID contratação PNCP: </span>
                      <br>
                      <span>{{ data.numeroControlePNCP }}</span>
                    </b-col>
                    <b-col
                      cols="12"
                      md="3"
                      lg="3"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Fonte: </span>
                      <br>
                      <b-button
                        v-if="data.linkSistemaOrigem"
                        id="btn-link-platform"
                        type="button"
                        variant="outline-primary"
                        class="mb-1 mb-sm-0 mr-0 p-0"
                        @click="goToPlatform()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiLink"
                          size="20"
                        />
                        <span class="ml-50 platformName">{{ data.usuarioNome }}</span>
                      </b-button>
                      <span v-else> {{ data.usuarioNome }} </span>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col
                      cols="12"
                      md="12"
                      lg="12"
                    >
                      <span class="setColorPrimary font-weight-bolder mr-50">Objeto: </span>
                      <br>
                      <span>{{ data.objetoCompra }}</span>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body>
                <b-tabs
                  class="searchBiddingTab"
                  card
                >
                  <b-tab
                    class="p-0"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 tabIconTitle"
                          type="mdi"
                          :path="mdiPackageVariant"
                        />
                        <span class="d-inline">Itens</span>
                      </b-card-title>
                    </template>
                    <b-card-body
                      class="p-0"
                    >
                      <pncp-items
                        ref="pncpItems"
                        :cnpj="cnpj"
                        :year="year"
                        :seq="seq"
                        :items-amount="itemsAmount"
                      />
                    </b-card-body>
                  </b-tab>
                  <b-tab
                    class="p-0"
                  >
                    <template #title>
                      <b-card-title>
                        <svg-icon
                          class="mr-50 tabIconTitle"
                          type="mdi"
                          :path="mdiFileDocumentOutline"
                        />
                        <span class="d-inline">Arquivos</span>
                      </b-card-title>
                    </template>
                    <b-card-body
                      class="p-0"
                    >
                      <pncp-bidding-files
                        :files="files"
                      />
                    </b-card-body>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="d-flex justify-content-end buttonsSaveCancel"
            >
              <b-button
                v-if="!isImported"
                id="btn_import"
                variant="primary"
                class="mr-0 mr-sm-1 py-50"
                @click="showModaImportBid()"
              >
                <svg-icon
                  type="mdi"
                  :path="mdiImport"
                  size="20"
                />
                Importar Licitação
              </b-button>
              <b-button
                id="btn_go_back"
                variant="outline-primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="backToPage()"
              >
                Voltar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="idModalImportBid"
      ref="modalImportBid"
      no-close-on-backdrop
      no-close-on-esc
      title="Confirmar?"
      centered
      cancel-variant="outline-primary"
      @ok="importBidding()"
    >
      <template #modal-cancel>
        Não
      </template>
      <template #modal-ok>
        Sim
      </template>
      <b-form
        class="mt-1"
      >
        <b-row>
          <b-col>
            <span
              class="d-flex justify-content-center px-2 fixLayoutTextModal"
            >
              Deseja importar esse edital para a sua lista de licitações do sistema?
            </span>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="idModalImportingBid"
      ref="modalImportingBid"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
    >
      <div class="pt-5 pb-4">
        <div class="d-flex justify-content-center">
          <b-spinner
            id="spinnerImport"
            variant="primary"
            type="grow"
          />
        </div>
        <h4
          class="d-flex justify-content-center mt-2 pl-50"
        >
          Importando...
        </h4>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BButton,
  BForm,
  BSpinner,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiPackageVariant,
  mdiFileDocumentOutline,
  mdiLink,
  mdiImport,
  mdiClipboardCheckOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import router from '@/router'
import syslic from '@/syslic'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import PncpItems from './PncpItems.vue'
import PncpBiddingFiles from '@/views/pncp/PncpBiddingFiles.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BTab,
    BTabs,
    BButton,
    BForm,
    BSpinner,
    SpinLoader,
    SvgIcon,
    PncpItems,
    PncpBiddingFiles,
  },
  data() {
    const isLoaded = false
    const isImported = false
    const { cnpj, year, seq } = router.currentRoute.params

    const currentPage = router.currentRoute.params.page ? router.currentRoute.params.page : 1

    const biddingImported = {
      id: '',
    }

    const data = {
      valorTotalEstimado: '',
      valorTotalHomologado: null,
      orgaoSubRogado: null,
      unidadeOrgao: {},
      unidadeSubRogada: null,
      numeroCompra: '',
      dataAtualizacao: '',
      dataInclusao: '',
      dataPublicacaoPncp: '',
      modalidadeId: '',
      srp: false,
      anoCompra: '',
      sequencialCompra: '',
      orgaoEntidade: {},
      dataAberturaProposta: '',
      dataEncerramentoProposta: '',
      informacaoComplementar: null,
      processo: '',
      objetoCompra: '',
      amparoLegal: {},
      linkSistemaOrigem: '',
      justificativaPresencial: null,
      existeResultado: false,
      numeroControlePNCP: '',
      modalidadeNome: '',
      orcamentoSigilosoCodigo: '',
      orcamentoSigilosoDescricao: '',
      situacaoCompraId: '',
      situacaoCompraNome: '',
      tipoInstrumentoConvocatorioCodigo: '',
      tipoInstrumentoConvocatorioNome: '',
      modoDisputaId: '',
      modoDisputaNome: '',
      usuarioNome: '',
    }
    const itemsAmount = 0
    const files = []

    return {
      isLoaded,
      isImported,
      cnpj,
      year,
      seq,
      currentPage,
      biddingImported,
      data,
      itemsAmount,
      files,
    }
  },
  created() {
    this.fetchData()
    this.fetchImportStatus()
  },
  methods: {
    fetchData() {
      syslic
        .pncp
        .fetchBidding(this.cnpj, this.year, this.seq)
        .then(response => {
          this.data = response.data
          return syslic.pncp.fetchBiddingFiles(this.cnpj, this.year, this.seq)
        })
        .then(response => {
          this.files = response.data
          return syslic.pncp.fetchBiddingItemsAmount(this.cnpj, this.year, this.seq)
        })
        .then(response => {
          this.itemsAmount = response.data
          this.isLoaded = true
        })
        .catch(error => {
          if (error.message && error.message.includes('timeout')) {
            this.$swal({
              title: 'Atenção!',
              text: 'O site do PNCP está lento, por favor, tente novamente.',
              icon: 'warning',
              showConfirmButton: false,
              timer: 10000,
            })
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Não foi possível encontrar esta licitação',
                text: 'Não foi possível encontrar esta licitação no Portal Nacional de Contratações Publicas, verifique o link de acesso e tente novamente.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            router.push({ name: 'bidding-search' })
          }
        })
    },
    goToPlatform() {
      const link = this.data.linkSistemaOrigem
      if (link) window.open(link, '_blank')
    },
    importBidding() {
      const biddingData = {
        cnpj: this.cnpj,
        year: this.year,
        seq: this.seq,
      }
      this.$refs.modalImportingBid.show()

      syslic
        .pncp
        .importBidding(biddingData)
        .then(() => {
          this.$refs.modalImportingBid.hide()
          this.$swal({
            title: 'Licitação importada com sucesso.',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: 4000,
          })

          this.fetchImportStatus()
        })
        .catch(error => {
          this.$refs.modalImportingBid.hide()
          let textError = 'Não foi possível importar a licitação, por favor entre em contato com o administrador do sistema.'
          if (error.data.error.includes('duplicate key value violates unique constraint')) {
            textError = 'Não foi possivel importar esta licitação porque já existe uma licitação cadastrada com o mesmo Nº Pregão e UASG/OC.'
          } else if (error.data.error.includes('erro there is no initial interest for the bidding')) {
            textError = 'Não foi possivel importar esta licitação porque não existe interesse inicial para a licitação.'
          } else if (error.data.error.includes('error there is no initial status for the bidding')) {
            textError = 'Não foi possivel importar esta licitação porque não existe status inicial para a licitação.'
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao importar esta licitação.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showModaImportBid() {
      this.$refs.modalImportBid.show()
    },
    fetchImportStatus() {
      const params = {
        cnpj: this.cnpj,
        year: this.year,
        seq: this.seq,
      }

      syslic
        .pncp
        .fetchBiddingsImported(params)
        .then(response => {
          const { data } = response
          this.isImported = data.count > 0
          if (this.isImported) {
            this.biddingImported = data.results[0].bidding_set
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível verificar se esta licitação foi importada.',
              text: 'Não foi possível requisitar o status de importação desta licitação',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    goToBidding() {
      router.push({ name: 'bidding-view', params: { id: this.biddingImported.id } }).catch(() => {})
    },
    backToPage() {
      router.push({ name: 'bidding-search', params: { page: this.currentPage } }).catch(() => {})
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiPackageVariant,
      mdiFileDocumentOutline,
      mdiLink,
      mdiImport,
      mdiClipboardCheckOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.cardTitle {
  background: #F6F6F9;
}

.setColorPrimary {
  color: $primary;
}

#btn-link-platform {
  border: none !important;
}

.platformName {
  font-weight: 400;
  color: #6e6b7b;
}

.tabIconTitle {
  width: 21px;
  height: 21px;
}

.searchBiddingTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.searchBiddingTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.searchBiddingTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.searchBiddingTab .nav-link.active {
  background-color: #e2edf3;
}

.searchBiddingTab .nav-link.active h4 {
  color: $primary;
}

.searchBiddingTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
 .searchBiddingTab .card-body {
  padding-left: 1.0rem;
  padding-right: 1.0rem;
 }

 .searchBiddingTab .tab-content {
   margin-top: -1rem;
 }

 .buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}

#idModalImportBid .vc-slider {
  width: 100% !important;
}

#idModalImportBid .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: center;

  .btn{
    min-width: 107px;
  }
}

.fixLayoutTextModal {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.roundedTopRightCorner {
  border-radius: 0 2rem 0 0;
}

#btnBiddingImported {
  background-color: $primary;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  #icon-btn-bid-imported {
    background-color: white;
    border-radius: 100px;
    padding: 0.3em;

    path {
      color: $primary;
    }
  }
}

#btnBiddingImported:hover {
  #icon-btn-bid-imported path {
    color: $gray-900;
  }
}

#spinnerImport {
  width: 5rem;
  height: 5rem;
}

#idModalImportingBid {
  #idModalImportingBid___BV_modal_content_{
    border-radius:20px !important;
  }
}
</style>
